.post {
    display: flex;
    align-items: flex-start;
    border-bottom: 1px solid var(--twitter-background);
    /* padding-bottom: 10px; */
  }
  
  .post__body {
    flex: 1;
    padding: 5px;
    margin-right: 10px;
  }
  
  .post__body > img {
    border-radius: 20px;
    max-width: 100%; /* Set a maximum width to ensure the image fits within its container */
    height: auto; /* Automatically adjust the height to maintain aspect ratio */
    margin-bottom: 5px;
  }
  
  .post__footer {
    display: flex;
    justify-content: space-between;
    margin-left: -10px;
    margin-top: -10px;
  }

  .post__header {
    display: flex;
    justify-content: space-between;
    align-items: center; /* To vertically align both .post__headerLeft and .post__headerRight */
  }
  
  .post__bodyContent {
    margin-top: -15px;
    margin-bottom: 15px;
    font-size: 15px;
  }
  
  .post__headerText > h3 {
    font-size: 15px;
  }

  .post__headerLeft {
    /* flex-grow: 1; Grow to take available space */
    text-align: left; /* Align content to the left */
  }
  
  .post__headerRight {
    text-align: right; /* Align content to the right */
  }
  
  .post__badge {
    font-size: 14px !important;
    color: var(--twitter-color);
  }
  
  .post__headerSpecial {
    font-weight: 600;
    font-size: 12px;
    color: gray;
  }
  
  .post__avatar {
    padding: 5px;
    margin-top: 10px;
    margin-left: 10px;
  }


  .popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
  }

  /* .show-more-container {
    display: flex;
    justify-content: space-between; 
    align-items: center;
    margin-top: -10px;
  } */

  .show-more-button {
    background: none;
    color: #2196f3;
    border: none;
    cursor: pointer;
    font-size: 14px;
}
  
  /* .popup__content {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    text-align: center;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  }
  
  .popup h2 {
    color: blue;
  }
  
  .popup p {
    margin-top: 10px;
  }
  
  .popup__buttons {
    margin-top: 20px;
  }
  
  .popup__buy-button,
  .popup__sell-button {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    margin-right: 10px;
  }
  
  .popup__buy-button span,
  .popup__sell-button span {
    display: block;
    font-size: 14px;
    margin-top: 5px;
  }
  
  .popup__close-button {
    background-color: red;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    margin-top: 20px;
    cursor: pointer;
  } */
