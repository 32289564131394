/* StockList.css */

.stock-list {
    flex: 0.4;
    border-right: 1px solid var(--twitter-background);
    min-width: fit-content;
  }

  .stock-list__header {
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 100;
    border: 1px solid var(--twitter-background);
    padding: 15px 20px;
  }
  
  .stock-list__header > h2 {
    font-size: 20px;
    font-weight: 800;
  }

  .stock {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--twitter-background);
    padding: 20px;
  }

  
  /* .stock__info {
    flex: 1;
  }
  
  .stock__ticker {
    font-size: 18px;
    margin-bottom: 5px;
  }
  
  .stock__name {
    font-size: 14px;
    color: gray;
  }
  
  .stock__price {
    font-size: 18px;
    font-weight: bold;
  }
   */
